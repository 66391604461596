import {useGlobalStore} from "../../../store";
import {Autocomplete, Box, Button, Grid, Paper, Slider, TextField, Typography} from "@mui/material";
import {useRewardMappings} from "../../../apollo/hooks/useRewardMappings";
import {useRewards} from "../../../apollo/hooks/useRewards";
import {alertVariants} from "../../../enums/alertVariants";
import {useEffect, useState} from "react";
import {BiSave} from "react-icons/bi";

const marks = [
    {
        value: 0,
    },
    {
        value: 30,
    },
    {
        value: 60,
    },
    {
        value: 90,
    },
    {
        value: 120,
    }
];

const EditAlert = (props) => {

    const selectedRewardMapping = useGlobalStore((state) => state.selectedRewardMapping)
    const {
        rewardMappings,
        updateRewardMapping,
        loading: rewardMappingsLoading,
        error: rewardMappingsError
    } = useRewardMappings()
    const {rewards, loading: rewardsLoading, error: rewardsError} = useRewards()

    const [duration, setDuration] = useState(5)
    const [selectedType, setSelectedType] = useState(null);
    const [selectedReward, setSelectedReward] = useState(null);
    const [name, setName] = useState("");

    useEffect(() => {
        if (!rewardMappingsLoading || rewardMappings) {
            const activeRewardMapping = rewardMappings.filter(rewardMapping => rewardMapping.id === selectedRewardMapping)[0]
            setName(activeRewardMapping?.customProps.name)
            setSelectedReward(rewards.filter(x => x.id === activeRewardMapping.triggeredBy[0]))
            setSelectedType(Object.keys(alertVariants).filter(x => x === activeRewardMapping.template));
            setDuration(activeRewardMapping?.customProps.duration)
        }
    }, [selectedRewardMapping])

    if (rewardMappingsError || rewardsError) return 'Error...'
    if (rewardMappingsLoading || rewardsLoading) return 'Loading...'

    const handleSelectReward = (event, index) => {
        if (index) {
            setSelectedReward(rewards.filter(x => x.id === index.id));
        } else {
            setSelectedReward(null)
        }
    }

    const handleSelectType = (event, index) => {
        if (index) {
            setSelectedType(Object.keys(alertVariants).filter(x => x === index));
        } else {
            setSelectedType(null)
        }
    }

    const handleDurationSliderChange = (event, newValue) => {
        setDuration(newValue);
    };

    const handleEditRewardMapping = () => {
        const payload = {
            customProps: {name: name, duration: duration},
            template: selectedType[0],
            triggeredBy: selectedReward[0].id
        }
        updateRewardMapping(payload, selectedRewardMapping).then(() => {
            props.onAlertEdited()
        })
    }

    const renderEditForm = () => {
        return (
            <Grid container spacing={1}>
                <Grid item xs={12}>
                    <Box pt={1} px={1.8}>
                        <Typography variant={'body2'}>
                            Anzeigedauer: {duration} Sekunden
                        </Typography>
                        <Box>
                            <Slider
                                onChange={handleDurationSliderChange}
                                value={duration}
                                min={5}
                                max={120}
                                marks={marks}
                            />
                        </Box>
                    </Box>
                </Grid>
                <Grid item xs={12}>
                    <TextField value={name} fullWidth onChange={(e) => setName(e.target.value)} variant={'filled'}
                               label={'Bezeichnung'}></TextField>
                </Grid>
                <Grid item xs={5}>
                    <Autocomplete
                        id="customer"
                        onChange={handleSelectReward}
                        value={selectedReward && selectedReward[0]}
                        options={rewards}
                        getOptionLabel={option => option.title + " - " + option.cost}
                        renderInput={params => (
                            <TextField
                                {...params}
                                label={'Reward'}
                                variant="filled"
                            />
                        )}
                    />
                </Grid>
                <Grid item xs={5}>
                    <Autocomplete
                        id="customer"
                        onChange={handleSelectType}
                        value={selectedType && selectedType[0]}
                        options={Object.keys(alertVariants)}
                        getOptionLabel={option => alertVariants[option]}
                        renderInput={params => (
                            <TextField
                                {...params}
                                label={'Alerttyp'}
                                variant="filled"
                            />
                        )}
                    />
                </Grid>
                <Grid item xs={2}>
                    <Button disabled={!name || !selectedReward || !selectedType}
                            onClick={() => handleEditRewardMapping()} disableElevation
                            sx={{height: '100%', fontSize: '1.5rem'}} variant={'contained'} fullWidth>
                        <BiSave/>
                    </Button>
                </Grid>
            </Grid>
        )
    }

    return (
        <Paper>
            <Box p={1}>
                {selectedRewardMapping ? renderEditForm() :
                    <Typography variant={'body2'}>Kein Alert ausgewählt</Typography>}
            </Box>
        </Paper>
    )
}
export default EditAlert