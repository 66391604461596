const token = localStorage.getItem('jwt');

export function getToken() {
    if(token) {
        return token;
    } else {
        return false;
    }
}

export function setToken(jwt) {
    localStorage.setItem("jwt", "")
    if(jwt) {
        localStorage.setItem("jwt", jwt)
        window.location.href = window.location.href.split('?')[0]
    }
}

export function removeToken() {
    localStorage.setItem("jwt", "")
    window.location.href = window.location.href.split('?')[0]
}

export function getJwtContents() {
    return parseJwt(getToken())
}

function parseJwt (token) {
    if(token) {
        var base64Url = token.split('.')[1];
        var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
        var jsonPayload = decodeURIComponent(atob(base64).split('').map(function(c) {
            return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        }).join(''));

        return JSON.parse(jsonPayload);
    } else {
        return false;
    }
};