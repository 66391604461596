import {Box, Container, Grid, Stack} from "@mui/material";
import styles from './settings.module.css'
import LoginGate from "../LoginGate";
import Alerts from "./Alerts/Alerts";
import CreateAlert from "./Alerts/CreateAlert";
import Header from "./Header";
import EditAlert from "./Alerts/EditAlert";
import {useState} from "react";

const Settings = () => {

    const [event, setEvent] = useState(true)

    return (
        <Box className={styles.settingsWrapper} p={5}>
            <LoginGate>
                <Container maxWidth={'lg'}>
                    <Stack direction={'column'} spacing={1}>
                        <Header/>
                        <Box>
                            <Grid container spacing={1}>
                                <Grid item xs={12} md={6}>
                                    <Stack direction={'column'} spacing={1}>
                                        <Alerts event={event}/>
                                        <CreateAlert onAlertCreated={() => setEvent(!event)}/>
                                    </Stack>
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <EditAlert onAlertEdited={() => setEvent(!event)}/>
                                </Grid>
                            </Grid>
                        </Box>
                    </Stack>
                </Container>
            </LoginGate>
        </Box>
    )
}

export default Settings