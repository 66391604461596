import {gql} from '@apollo/client';

export const TWITCH_REDEMPTION_EVENTS = gql`
  subscription twitchRedemptionEvents($byId: String) {
    twitchRedemptionEvents(byId: $byId) {
        eventId
        redemptionDate
        rewardDescription
        rewardId
        rewardMessage
        rewardTitle
        streamerDisplayName
        streamerId
        streamerUsername
        userChatColor
        userDisplayName
        userProfilePic
        userId
        userUsername
    }
  }
`;