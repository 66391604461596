import {useMutation, useQuery} from '@apollo/client'
import {REWARDMAPPINGS_QUERY} from "../queries/rewardMappings";
import {CREATE_REWARDMAPPING} from "../mutations/createRewardMapping";
import {UPDATE_REWARDMAPPING} from "../mutations/updateRewardMapping";
import {REMOVE_REWARDMAPPING} from "../mutations/removeRewardMapping";
import {getToken} from "../../jwt";

export const useRewardMappings = () => {

    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const jwt = urlParams.get('jwt')

    const fetchQuery = REWARDMAPPINGS_QUERY

    const {
        data: dataQuery,
        loading: loadingQuery,
        error: errorQuery,
        refetch: refetchQuery,
    } = useQuery(fetchQuery, {
        context: {
            headers: {
                authorization: `${
                    jwt ? jwt : getToken()
                }`,
            },
        },
        fetchPolicy: 'no-cache'
    })

    let rewardMappingsData = dataQuery?.rewardMappings;

    const [add, {loading: loadingAdd, error: errorAdd}] = useMutation(
        CREATE_REWARDMAPPING,
        {
            errorPolicy: "all",
            fetchPolicy: 'no-cache',
            onCompleted: (res) => {
                if (!res) return
                refetchQuery()
                console.log('rewardmapping created!')
            },
        },
    );

    const [update, {loading: loadingUpdate, error: errorUpdate}] = useMutation(
        UPDATE_REWARDMAPPING,
        {
            errorPolicy: "all",
            fetchPolicy: 'no-cache',
            onCompleted: (res) => {
                if (!res) return
                refetchQuery()
                console.log('rewardmapping updated!')
            },
        },
    );

    const [remove, {loading: loadingRemove, error: errorRemove}] = useMutation(
        REMOVE_REWARDMAPPING,
        {
            errorPolicy: "all",
            fetchPolicy: 'no-cache',
            onCompleted: (res) => {
                if (!res) return
                refetchQuery()
                console.log('rewardmapping removed!')
            },
        },
    );

    return {
        rewardMappings: rewardMappingsData,
        refetchRewardMappings: () => refetchQuery(),
        loading: loadingQuery || loadingAdd || loadingUpdate,
        error: errorQuery || errorAdd || errorRemove || errorUpdate,
        addRewardMapping: (data) => add({
            variables: {
                data: data
            }
        }),
        updateRewardMapping: (data, id) => update({
            variables: {
                data: data,
                id: id,
            }
        }),
        removeRewardMapping: (id) => remove({
            variables: {
                id: id
            }
        }),
    }
}