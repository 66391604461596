import {Autocomplete, Box, Button, Grid, Paper, Slider, TextField, Typography} from "@mui/material";
import {useRewardMappings} from "../../../apollo/hooks/useRewardMappings";
import {useRewards} from "../../../apollo/hooks/useRewards";
import {useState} from "react";
import {alertVariants} from "../../../enums/alertVariants";
import {IoAdd} from "react-icons/io5";

const marks = [
    {
        value: 0,
    },
    {
        value: 30,
    },
    {
        value: 60,
    },
    {
        value: 90,
    },
    {
        value: 120,
    }
];

const CreateAlert = (props) => {

    const {
        addRewardMapping,
        refetchRewardMappings,
        error: rewardsMappingError,
        loading: rewardsMappingLoading
    } = useRewardMappings()
    const {rewards, refetchRewards, error: rewardsError, loading: rewardsLoading} = useRewards()

    const [duration, setDuration] = useState(5)
    const [selectedType, setSelectedType] = useState(null);
    const [selectedReward, setSelectedReward] = useState(null);
    const [name, setName] = useState("");

    if (rewardsMappingLoading || rewardsLoading) return "Loading..."
    if (rewardsMappingError || rewardsError) return "Error..."

    const handleSelectReward = (event, index) => {
        if (index) {
            setSelectedReward(rewards.filter(x => x.id === index.id));
        } else {
            setSelectedReward(null)
        }
    }

    const handleSelectType = (event, index) => {
        if (index) {
            setSelectedType(Object.keys(alertVariants).filter(x => x === index));
        } else {
            setSelectedType(null)
        }
    }

    const handleDurationSliderChange = (event, newValue) => {
        setDuration(newValue);
    };

    const handleCreateRewardMapping = () => {
        const payload = {
            customProps: {name: name, duration: duration},
            template: selectedType[0],
            triggeredBy: selectedReward[0].id
        }
        addRewardMapping(payload).then(() => {
            setSelectedType(null)
            setSelectedReward(null)
            setName("")
            setDuration(5)
            props.onAlertCreated()
        })
    }

    return (
        <Paper>
            <Box p={1}>
                <Grid container spacing={1}>
                    <Grid item xs={12}>
                        <Box pt={1} px={1.8}>
                            <Typography variant={'body2'}>
                                Anzeigedauer: {duration} Sekunden
                            </Typography>
                            <Box>
                                <Slider
                                    onChange={handleDurationSliderChange}
                                    value={duration}
                                    min={5}
                                    max={120}
                                    marks={marks}
                                />
                            </Box>
                        </Box>
                    </Grid>
                    <Grid item xs={12}>
                        <TextField value={name} fullWidth onChange={(e) => setName(e.target.value)} variant={'filled'}
                                   label={'Bezeichnung'}></TextField>
                    </Grid>
                    <Grid item xs={5}>
                        <Autocomplete
                            id="customer"
                            onChange={handleSelectReward}
                            value={selectedReward && selectedReward[0]}
                            options={rewards}
                            getOptionLabel={option => option.title + " - " + option.cost}
                            renderInput={params => (
                                <TextField
                                    {...params}
                                    label={'Reward'}
                                    variant="filled"
                                />
                            )}
                        />
                    </Grid>
                    <Grid item xs={5}>
                        <Autocomplete
                            id="customer"
                            onChange={handleSelectType}
                            value={selectedType && selectedType[0]}
                            options={Object.keys(alertVariants)}
                            getOptionLabel={option => alertVariants[option]}
                            renderInput={params => (
                                <TextField
                                    {...params}
                                    label={'Alerttyp'}
                                    variant="filled"
                                />
                            )}
                        />
                    </Grid>
                    <Grid item xs={2}>
                        <Button disabled={!name || !selectedReward || !selectedType}
                                onClick={() => handleCreateRewardMapping()} disableElevation
                                sx={{height: '100%', fontSize: '1.5rem'}} variant={'contained'} fullWidth>
                            <IoAdd/>
                        </Button>
                    </Grid>
                </Grid>
            </Box>
        </Paper>
    )
}

export default CreateAlert