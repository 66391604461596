import {Box, Button, Container, Paper, Stack} from "@mui/material";
import {getJwtContents, setToken} from "../../jwt";
import {useEffect} from "react";

export const LoginGate = ({children}) => {

    const baseUrl = "/api/twitch/init?from="
    const panelUrl = `/settings`

    useEffect(() => {
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        const jwt = urlParams.get('jwt')
        if (jwt) setToken(jwt)
    }, [])

    const handleAuthentication = () => {
        window.location.href = baseUrl + panelUrl
    }

    const isLoggedIn = localStorage.getItem('jwt') && getJwtContents()?.username !== null ? true : false

    return <>{isLoggedIn ? children :
        <Container maxWidth={'sm'}>
            <Box height={'100vh'} display={'grid'} alignItems={'center'} justifyContent={'center'}>
                <Paper>
                    <Box p={1}>
                        <Stack direction={'column'} spacing={1}>
                            <Stack direction={'row'} spacing={1}>
                                <Button variant={"contained"} color={"primary"} onClick={() => handleAuthentication()}>Mit
                                    Twitch Anmelden</Button>
                            </Stack>
                        </Stack>
                    </Box>
                </Paper>
            </Box>
        </Container>}</>;
}

export default LoginGate
