import './theme.css';
import {createTheme} from "@mui/material";

export default createTheme({
    palette: {
        background: {
            default: '#0f1110',
        },
        text: {
            primary: "#e7ebf0"
        },
        primary: {
            main: '#f08026',
            contrastText: '#ffffff'
        },
        secondary: {
            main: '#d21f3c',
            contrastText: '#ffffff'
        }
    },
    components: {
        MuiOutlinedInput: {
            styleOverrides: {
                root: {
                    color: '#ffffff',
                    backgroundColor: '#343431',
                    '& fieldset': {
                        border: '0px',
                    },
                },
            },
        },
        MuiFilledInput: {
            styleOverrides: {
                root: {
                    backgroundColor: '#0f1110'
                }
            }
        },
        MuiPaper: {
            styleOverrides: {
                root: {
                    backgroundColor: '#202524',
                    boxShadow: 'none',
                },
            },
        },
        MuiListItemText: {
            styleOverrides: {
                primary: {
                    fontSize: "1.5rem",
                    lineHeight: "1.5",
                    letterSpacing: "0.1px",
                    fontWeight: "300",
                },
                secondary: {
                    fontSize: "1rem",
                    lineHeight: "1.2rem",
                    letterSpacing: "0.2px",
                    color: '#9b9b9b',
                }
            }
        },
        MuiCard: {
            styleOverrides: {
                root: {
                    backgroundColor: '#0d1b2f',
                    borderRadius: '10px',
                },
            },
        },
        MuiTableCell: {
            styleOverrides: {
                head: {
                    color: '#afb6c5'
                },
                body: {
                    color: '#afb6c5',
                    borderColor: 'transparent',
                    padding: '16px'
                },
                root: {
                    padding: '16px',
                    borderBottom: '0px'
                }
            },
        },
        MuiTypography: {
            styleOverrides: {
                h1: {
                    fontSize: "2.25rem",
                    lineHeight: "1.22222",
                    letterSpacing: "0.2px",
                    fontWeight: "600",
                },
                h2: {
                    fontSize: "1.5rem",
                    lineHeight: "1.5",
                    letterSpacing: "0.1px",
                    fontWeight: "300",
                },
                h3: {
                    fontSize: "4rem",
                    lineHeight: "5rem",
                    fontWeight: "800",
                },
                h4: {
                    fontSize: "3rem",
                    lineHeight: "3rem",
                    fontWeight: "600",
                },
                h5: {
                    fontSize: "14rem",
                    lineHeight: "15rem",
                    fontWeight: "900",
                }
            },
        },
        MuiInputLabel: {
            styleOverrides: {
                root: {
                    color: "#e7ebf0",
                },
            },
        },
        MuiAutocomplete: {
            styleOverrides: {
                popupIndicator: {
                    color: '#ffffff'
                },
                clearIndicator: {
                    color: '#ffffff'
                },
            }
        },
        MuiButton: {
            styleOverrides: {
                root: { // Name of the rule
                    textTransform: 'none', // Some CSS
                    color: '#e7ebf0', // Some CSS
                    borderRadius: '4px'
                },
                containedPrimary: {
                    boxShadow: 'none',
                    backgroundColor: '#f08026',
                    '&$disabled': {
                        background: '#132f4c',
                        color: '#e7ebf0'
                    }
                },
                containedSecondary: {
                    boxShadow: 'none',
                    background: '#d21f3c',
                    '&$disabled': {
                        background: '#132f4c',
                        color: '#e7ebf0'
                    }
                },
                outlinedPrimary: {
                    borderWidth: '1px',
                    '&:hover': {
                        borderWidth: '1px'
                    }
                },
                outlinedSecondary: {
                    borderWidth: '1px',
                    '&:hover': {
                        borderWidth: '1px'
                    }
                },
                contained: {
                    boxShadow: 'none',
                    backgroundColor: '#172840',
                    color: '#e7ebf0',
                    '&$disabled': {
                        backgroundColor: '#132f4c',
                        color: '#afb6c5'
                    },
                    '&:hover': {
                        backgroundColor: '#b25917'
                    }
                },
            },
        },
    },
    typography: {
        useNextVariants: true,
    },
})
