import './App.css';
import {BrowserRouter} from "react-router-dom";
import {CssBaseline, ThemeProvider} from "@mui/material";
import Router from "./views/Router";
import theme from "../src/theme/theme";
import {useState} from "react";
import {onError} from '@apollo/client/link/error'
import {authMiddleware, DefaultApolloClient, defaultHttpLink, splitLink} from "./apollo/clients/apolloClients";
import {ApolloClient, ApolloProvider, concat, InMemoryCache} from "@apollo/client";

function App() {

    const [networkError, setNetworkError] = useState(null)

    const onErrorLink = onError(({graphQLErrors, networkError}) => {
        if (graphQLErrors) {
            for (let err of graphQLErrors) {
                console.log(err)
            }
        }

        // To retry on network errors, we recommend the RetryLink
        // instead of the onError link. This just logs the error.
        if (networkError) {
            console.log(`[Network error]: ${networkError}`)
            setNetworkError(networkError)
        }
    })

    const link = concat(authMiddleware, onErrorLink.concat(splitLink));
    const cache = new InMemoryCache();
    const client = new ApolloClient({
        link,
        cache
    });

    return (
        <ApolloProvider client={client}>
            <ThemeProvider theme={theme}>
                <BrowserRouter>
                    <CssBaseline/>
                    <Router/>
                </BrowserRouter>
            </ThemeProvider>
        </ApolloProvider>
    );
}

export default App;
