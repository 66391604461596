import styles from './overlay.module.css'
import {Box, Typography} from "@mui/material";
import Alertbox from "./Alertbox";
import {useState} from "react";
import {AnimatePresence} from "framer-motion";
import {alertVariants} from "../../enums/alertVariants";
import {useParams} from "react-router-dom";
import {useRewardMappings} from "../../apollo/hooks/useRewardMappings";
import {TWITCH_REDEMPTION_EVENTS} from "../../apollo/subscriptions/twitchRedemptionEvents";
import {useSubscription} from "@apollo/client";
import sound from './sounds/achievement.mp3'

const Overlay = () => {

    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const jwt = urlParams.get('jwt')
    const routeParams = useParams()
    const audio = new Audio(sound)

    const [showAlert, setShowAlert] = useState(false)
    const [alertData, setAlertData] = useState({})
    //const audio = new Audio('../../../public/sounds/achievement.mp3');

    const {
        rewardMappings,
        loading: rewardMappingsLoading,
        error: rewardMappingsError
    } = useRewardMappings()

    useSubscription(TWITCH_REDEMPTION_EVENTS, {
        variables: {
            byId: rewardMappings && rewardMappings.find(mapping => mapping.id === routeParams.id)?.triggeredBy[0]
        },
        onSubscriptionData: raw => {
            setAlertData(raw.subscriptionData.data.twitchRedemptionEvents)
            if (rewardMappings) {
                triggerAlert(rewardMappings.find(rewardMapping => rewardMapping.id === routeParams.id)?.customProps.duration)
            }
            //console.log(raw.subscriptionData.data.twitchRedemptionEvents)
        }
    });

    const triggerAlert = (duration) => {
        setShowAlert(true)

        audio.play()
        const timeId = setTimeout(() => {
            // After 3 seconds set the show value to false
            setShowAlert(false)
        }, duration * 1000)

        return () => {
            clearTimeout(timeId)
        }
    }

    const handleRenderErrorBox = (errorMessage) => {
        return (
            <Box className={styles.errorBox} display={'flex'} alignItems={'center'} justifyContent={'center'}>
                <Typography variant={'h6'} color={'#ffffff'}>
                    {errorMessage}
                </Typography>
            </Box>
        )
    }

    if (rewardMappingsError) return handleRenderErrorBox("Reward Verknüpfungs Fehler.")
    if (!routeParams.id) return handleRenderErrorBox("Keine Alert ID angegeben.")
    if (rewardMappingsLoading) return handleRenderErrorBox("Lade Daten...")
    if (!jwt) return handleRenderErrorBox("Nicht Authentifiziert.")
    if (!rewardMappings.find(rewardMapping => rewardMapping.id === routeParams.id)) return handleRenderErrorBox("Kein Reward mit der ID " + routeParams.id + " gefunden.")

    const selectedRewardMapping = rewardMappings?.find(rewardMapping => rewardMapping.id === routeParams.id)

    return (
        <Box className={styles.overlayWrapper}>
            <AnimatePresence mode={'wait'}>
                {showAlert &&
                    <Alertbox variant={alertVariants[selectedRewardMapping.template]}
                              image={alertData.userProfilePic ? alertData.userProfilePic : "https://static-cdn.jtvnw.net/jtv_user_pictures/11cfc6bb-eed4-4c67-9dba-13e00316c871-profile_image-150x150.png"}
                              color={alertData.userChatColor ? alertData.userChatColor : "#ff1f1f"}
                              title={"New Pandor Achievement!"}
                              text={alertData.rewardMessage ? alertData.rewardMessage : ""}/>}
            </AnimatePresence>
        </Box>
    )
}

export default Overlay