import {useQuery} from '@apollo/client'
import {REWARDS_QUERY} from "../queries/rewards";

export const useRewards = () => {

    const fetchQuery = REWARDS_QUERY

    const {
        data: dataQuery,
        loading: loadingQuery,
        error: errorQuery,
        refetch: refetchQuery,
    } = useQuery(fetchQuery, {
        fetchPolicy: 'no-cache'
    })

    let rewardData = dataQuery?.rewards;

    return {
        rewards: rewardData,
        refetchRewards: () => refetchQuery(),
        loading: loadingQuery,
        error: errorQuery,
    }
}