import styles from "../alertbox.module.css";
import {Box, Grid, Stack, Typography} from "@mui/material";
import {motion} from "framer-motion";
import {itemElement} from "../../../../framer";

const Achievement = (props) => {
    return (
        <motion.div
            initial={{y: 300, x: '-50%'}}
            animate={{y: 0, x: '-50%'}}
            exit={{y: 300, x: '-50%'}}
            transition={{type: 'spring', stiffness: 250, mass: 0.1, damping: 40}}
            className={styles.alertboxWrapper} style={{borderColor: props.color ? props.color : '#ff1f1f'}}>
            <Grid container display={'flex'} height={'100%'} alignItems={'center'} px={6}>
                <Grid item xs={3}>
                    <motion.div key={0} variants={itemElement}>
                        <Box className={styles.alertImageWrapper}
                             style={{borderColor: props.color ? props.color : '#ff1f1f'}}>
                            <img alt={'Alert'}
                                 src={props.image}
                                 className={styles.alertImage}/>
                        </Box>
                    </motion.div>
                </Grid>
                <Grid item xs={9}>
                    <Stack direction='column' className={styles.textContainer}>
                        <motion.div key={1} variants={itemElement}>
                            <Typography color={'#ff1f1f'} className={styles.alertTitle}>{props.title}</Typography>
                        </motion.div>
                        <motion.div key={2} variants={itemElement}>
                            <Typography color={'#ffffff'}
                                        className={styles.alertText}>{props.text ? props.text : "Ganz schön leer hier..."}</Typography>
                        </motion.div>
                    </Stack>
                </Grid>
            </Grid>
            <Box className={styles.triangle} sx={{background: props.color ? props.color : '#ff1f1f'}}/>
            <Box className={styles.icon}/>
            <Box className={styles.logo}/>
        </motion.div>
    )
}

export default Achievement