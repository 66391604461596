import {motion} from "framer-motion";
import {containerElement} from "../../../framer";
import {alertVariants} from "../../../enums/alertVariants";
import Achievement from "./Variants/Achievement";

const Alertbox = (props) => {

    const shortenText = (text) => {
        if (text.length > 50) return text.substring(0, 50) + "...";
        return text
    }

    const renderVariant = () => {
        switch (props.variant) {
            case alertVariants.achievement:
                return <Achievement image={props.image} color={props.color} title={props.title} text={shortenText(props.text)}/>
            default:
                return "Fehler..."
        }
    }

    return (
        <motion.div
            variants={containerElement}
            initial="hidden"
            whileInView="show"
        >
            {renderVariant()}
        </motion.div>
    )
}

export default Alertbox