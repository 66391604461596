import {Button, Paper, Stack, Typography} from "@mui/material";
import styles from "./header.module.css";
import {getJwtContents, removeToken} from "../../../jwt";

const Header = () => {

    const handleLogout = () => {
        removeToken()
    }

    return (
        <Paper className={styles.settingsHeader}>
            <Stack direction={'row'} alignItems={'center'} justifyContent={'space-between'} p={2}>
                <Typography variant={'body2'} color={'#ffffff'}>Eingeloggt als {getJwtContents()?.username}</Typography>
                <Stack direction={'column'} textAlign={'center'}>
                    <Typography variant={'h1'} color={'#ffffff'}>PESAS</Typography>
                    <Typography variant={'body2'} color={'#9b9b9b'}><span style={{ color: '#ffffff' }}>P</span>andor's <span style={{ color: '#ffffff' }}>E</span>vent <span style={{ color: '#ffffff' }}>S</span>ub <span style={{ color: '#ffffff' }}>A</span>chievement <span style={{ color: '#ffffff' }}>S</span>ystem ✨</Typography>
                </Stack>
                <Button variant={'contained'} disableElevation
                        onClick={() => handleLogout()}>Abmelden</Button>
            </Stack>
        </Paper>
    )
}
export default Header
