export const containerElement = {
    hidden: {opacity: 0},
    show: {
        opacity: 1,
        transition: {staggerChildren: 0.1}
    }
};
export const itemElement = {
    hidden: {y: 20, opacity: 0, height: '100%'},
    show: {y: 0, opacity: 1, transition: { type: "spring", stiffness: 200, mass: 0.1, damping: 40}}
};