import {ApolloClient, ApolloLink, concat, createHttpLink, InMemoryCache, split,} from '@apollo/client'
import {GraphQLWsLink} from "@apollo/client/link/subscriptions";
import {createClient} from "graphql-ws";
import {getMainDefinition} from "@apollo/client/utilities";
import create from "zustand";
import {getToken} from "../../jwt";

const auth = localStorage.getItem('jwt');
const queryString = window.location.search;
const urlParams = new URLSearchParams(queryString);
const jwt = urlParams.get('jwt')

export const useLoginHeader = create(set => ({
    authHeader: jwt ? jwt : getToken(),
    setAuthHeader: (authHeader) => set({authHeader})
}));

export const defaultHttpLink = createHttpLink({
    uri: `/api/gql`,
})

const {protocol, host} = window.location;
const wsLink = new GraphQLWsLink(
    createClient({
        url: `${protocol === 'https:' ? 'wss' : 'ws'}://${host}/api/gql`,
        connectionParams: {
            Authorization: jwt ? jwt : getToken(),
        }
    })
);

export const authMiddleware = new ApolloLink((operation, forward) => {
    // add the authorization to the headers
    operation.setContext({
        headers: {
            authorization: useLoginHeader.getState().authHeader,
        },
    })
    return forward(operation)
})

export const httpLink = concat(authMiddleware, defaultHttpLink);
export const splitLink = split(({query}) => {
        const definition = getMainDefinition(query);
        return (
            definition.kind === 'OperationDefinition' &&
            definition.operation === 'subscription'
        );
    },
    wsLink,
    httpLink
);
export const cache = new InMemoryCache()

export const DefaultApolloClient = new ApolloClient({
    link: splitLink,
    cache,
})
