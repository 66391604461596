import styles from './alerts.module.css'
import {Box, Fab, ListItem, ListItemText, Paper, Stack, Typography} from "@mui/material";
import {useRewardMappings} from "../../../apollo/hooks/useRewardMappings";
import {FiLink} from "react-icons/fi";
import {IoMdClose} from "react-icons/io";
import {alertVariants} from "../../../enums/alertVariants";
import {useRewards} from "../../../apollo/hooks/useRewards";
import {FaLongArrowAltRight} from "react-icons/fa";
import {useGlobalStore} from "../../../store";

const Alerts = () => {

    const setSelectedRewardMapping = useGlobalStore((state) => state.setSelectedRewardMapping)
    const selectedRewardMapping = useGlobalStore((state) => state.selectedRewardMapping)

    const {
        rewardMappings,
        refetchRewardMappings,
        removeRewardMapping,
        loading: rewardMappingsLoading,
        error: rewardMappingsError
    } = useRewardMappings()
    const {rewards, refetchRewards, loading: rewardsLoading, error: rewardsError} = useRewards()

    refetchRewards()
    refetchRewardMappings()

    if (rewardMappingsError || rewardsError) return 'Error...'
    if (rewardMappingsLoading || rewardsLoading) return 'Loading...'

    const renderAlerts = () => {
        return rewardMappings.map((alert, index) => (
            <Stack direction={'row'} alignItems={'center'} spacing={1}>
                <ListItem
                    onClick={() => setSelectedRewardMapping(alert.id)}
                    className={alert.id === selectedRewardMapping ? styles.activeListItem : styles.listItem}
                    key={index}
                >
                    <ListItemText
                        primary={<Typography variant={'h6'} className={styles.rewardTitle}>{alert.customProps?.name}</Typography>}
                        secondary={
                            <Stack direction={'row'} spacing={1} alignItems={'center'}>
                                <Typography>
                                    {rewards.find(reward => reward.id === alert.triggeredBy[0])?.title ? rewards.filter(reward => reward.id === alert.triggeredBy[0])[0].title : "Keine Belohnung zugewiesen"}
                                </Typography>
                                <FaLongArrowAltRight/>
                                <Typography>
                                    {alertVariants[alert.template]}
                                </Typography>
                            </Stack>}
                    />
                </ListItem>
                <Stack direction={'row'} spacing={1}>
                    <Fab disabled={!rewards.find(reward => reward.id === alert.triggeredBy[0])} onClick={() => navigator.clipboard.writeText(window.location.origin + "/" + alert.id + "?jwt="+localStorage.getItem('jwt'))}
                         size={'small'} color={'primary'} edge="end" aria-label="delete">
                        <FiLink/>
                    </Fab>
                    <Fab onClick={() => removeRewardMapping(alert.id)} size={'small'} color={'secondary'} edge="end"
                         aria-label="delete">
                        <IoMdClose/>
                    </Fab>
                </Stack>
            </Stack>
        ))
    }

    return (
        <Paper>
            <Box p={1}>
                {renderAlerts()}
            </Box>
        </Paper>
    )
}

export default Alerts