import {gql} from "@apollo/client";

export const REWARD_ENTITY = gql`
    fragment RewardEntity on CustomReward {
        id
        cost
        isEnabled
        isInStock
        isPaused
        maxRedemptionsPerStream
        title
    }
`;