import React from 'react';

import {Route, Routes} from "react-router-dom";
import Overlay from "../Overlay";
import Settings from "../Settings";

const Router = () => {
    return (
        <Routes>
            <Route path='/'>
                <Route index element={<Overlay/>}/>
                <Route path="/:id" element={<Overlay/>}/>
            </Route>
            <Route path='settings'>
                <Route index element={<Settings/>}/>
            </Route>
        </Routes>
    )
}

export default Router;